<!--新闻明细-->
<template>
  <div>
    <div class="title">
      <span>{{ news.title }}</span>
    </div>
    <div class="author">
      <span>{{ news.author }}</span>
      <span>{{ news.createTime }}</span>
    </div>
    <div class="body">
      <div v-html="news.content"></div>
    </div>
  </div>
</template>

<script>
export default {
  name: 'newsDetail',
  data() {
    return {
      news: {
        title: '《奇异博士2》次周票房为何大跌67% 北美这家观众调查机构不简单',
        createTime: '2022-05-16 11:57:40 ',
        author: '时光快讯Mtime ',
        content:
          '<p>CinemaScore是一家调查机构，成立于1979年，总部位于拉斯维加斯。该公司坚持在影院调查观众对观影体验的等级评价。该公司创始人的儿子表示，父亲想事先倾听“真正的人而非影评人”对这部电影的想法。</p>\n' +
          '<p>&nbsp;</p>\n' +
          '<p>和烂番茄新鲜指数不同，CinemaScore只会在影片上映后出分。</p>\n' +
          '<p>&nbsp;</p>\n' +
          '<p>目前，在北美25座城市有35到45支影院评分调查代表队。每次有新片上映，公司随机抽取城市的代表们会在周五将一张调查小卡片发给首映日的观众。</p>\n' +
          '<p>&nbsp;</p>\n' +
          '<p>卡片询问观众年龄、性别、电影在A+到F之间的评分、是否出租或购买电影的DVD或蓝光版和选择该电影的原因。</p>\n' +
          '<p>&nbsp;</p>\n' +
          '<p>通常每部电影公司都会收回400张卡片，公司预估65%的回应率和6%的误差范围。评价依照性别和年龄段（21岁以下、21岁-34岁、35岁及以上）。</p>\n' +
          '<p>&nbsp;</p>\n' +
          '<p>他们的调查包含了租用碟片和流媒体的意愿，打破了依照年龄和性别的人口统计方式的弊端。</p>\n' +
          '<p>&nbsp;</p>\n' +
          '<p>这家公司用相对笨拙的方法，得出了最为准确的观众评价，得到了电影公司高管的认可，慢慢成为业界标准。某好莱坞高管认为：他们的评分“并不总是正确的，但是一个非常好的指标，慢慢有了依赖。”</p>\n' +
          '<p style="text-align: center;" contenteditable="false">新版《凶火》评价很差</p>\n' +
          '</figcaption>\n' +
          '</figure>\n' +
          '<p>环球影业发行的新片《凶火》CinemaScore评分为C-，虽然恐怖片评分普遍不高，但这个也太低了。影片在流媒体Peacock同步发行，于是首周末只拿到380万美元的票房，周末排名第四。影片投资仅1200万美元，对环球来说损失会太大。</p>\n' +
          '<p>&nbsp;</p>\n' +
          '<p>其他影片排名，《坏蛋联盟》第四个周末拿到689万美元，排名第二。北美累计6628万美元， 全球累计1.65亿美元（内地1.3亿人民币）</p>\n' +
          '<p>&nbsp;</p>\n' +
          '<p>《刺猬索尼克2》第六个周末拿到455万美元，排名第三。北美累计1.75亿美元，全球累计3.55亿美元。</p>\n' +
          '<p>&nbsp;</p>\n' +
          '<p>《瞬息全宇宙》第八个周末拿到330万美元，排名第五。北美累计4710万美元，全球累计5182万美元，5月17日上线流媒体。</p>\n' +
          '<p>&nbsp;</p>\n' +
          '<p><strong>本周末新片</strong></p>\n' +
          '<p>导演：亚力克斯·嘉兰<br>主演：杰西·巴克利、罗里·金尼尔<br>剧情：讲述一个年轻女子在前夫去世后独自去英国乡间度假的故事。</p>',
      },
    }
  },
}
</script>

<style scoped>
.title {
  padding: 10px;
  font-size: 16px;
  font-family: PingFangSC-Semibold, PingFang SC;
  font-weight: 600;
  color: #333333;
  line-height: 18px;
}
.author {
  height: 17px;
  font-size: 12px;
  font-family: PingFangSC-Medium, PingFang SC;
  padding: 10px 20px;
  font-weight: 500;
  color: #6c95d0;
  line-height: 17px;
  display: flex;
  flex-direction: row;
  vertical-align: center;
  justify-content: space-between;
  span:last-child {
    color: #000;
  }
}
.body {
  padding: 10px;
  > img {
    max-width: 100% !important;
  }
}
</style>
